import React, { useState, useEffect } from "react";
import { api } from "../../../api";
import { useQuery } from "react-query";
import Applications from "./components/Applications";
import ScaleLoader_ from "../assessments/components/ScaleLoader";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Card from "./components/Card";
import { AssessementContext } from "../assessments/AssessmentContext";
import { defaultState } from "../assessments/components/defaultState";

const Dashboard = ({ width }) => {
  
  const [showFilter, hideFilter] = useState(false);
  const [assessment, setAssessment] = useState(defaultState);
  const location = useLocation()
  const [showRedirect, hideRedirect] = useState(false);
  const navigate = useNavigate();

  const { data, isLoading, isError } = useQuery(
    ["applications"],
    async () => {
      const data = await api.getApplications();
      return data.data; 
    },
    { 
      cacheTime: 30000,
       staleTime: 30000, 
       refetchOnMount: false, 
       refetchOnWindowFocus: false,
        refetchOnReconnect: false 
    }
  );

  const {
    isLoading: examsLoading,
    data: numberOfExams,
  } = useQuery(
    "user exams",
    async () => {
      const data = await api.getAssesments();
      return data;
    },
    { 
      select: data => data?.data?.upcoming_exams_count,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );

  const { data: mockAssessment, isLoading: mockExamLoading } = useQuery(
    "user-group-practice-assessment",
    async () => {
      const data = await api.getPracticeExams();
      return data;
    },
    {
     select: (response) => response?.data?.practice_exams_count,
     refetchOnMount: false, 
     refetchOnWindowFocus: false,
     refetchOnReconnect: false
    }
  );

  const { data: dashboardSummary, isLoading: dashboardSummaryLoading } = useQuery(
    "dashboard summary",
    async () => {
      const data = await api.getDashbardSummary();
      return data;
    },
    {
     select: (response) => response?.data?.data,
     refetchOnMount: false, 
     refetchOnWindowFocus: false, 
     refetchOnReconnect: false
    }
  );

  const { data: examResult, isLoading: examResultLoading, } = useQuery(
    ["get exam history"],
    async () => {
      const data = await api.getExamsHistory();
      return data.data;
    },
    { refetchOnMount: false, refetchOnWindowFocus: false, refetchOnReconnect: false }
  );

  useEffect(() => {
   hideRedirect(true);
  }, []);

  const user = JSON.parse(sessionStorage.getItem("user"));

  if (isError) {
    return <p>Error</p>;
  }

  return (
    <React.Fragment>   
      <AssessementContext.Provider value={{ assessment, setAssessment }}>
        {isLoading ? (
          <ScaleLoader_ />
        ) : (
          <>
            {/*( showRedirect && mockAssessment?.length) ? 
               <Redirect handleRedirect={hideRedirect} /> : null 
           */}
            <div
              className="rounded-md mt-1 bg-white p-4 gap-4"
              style={{
                display: "flex",
                width: "100%",
                // gridTemplateColumns: width > 768 ? "4fr 1.5fr" : "1fr",
                border: "1px solid #ccc",
              }}
            >
              <div className="w-full pt-4 md:pt-0">
                <div className="md:hidden flex justify-end">
                  <Link
                    to="/candidate/notifications"
                    className="md:hidden bg-[#6c0064] bi bi-bell text-white py-4 px-12 rounded-lg text-lg"
                  >
                    &nbsp; Notifications{" "}
                    <sup className="-mt-3 bg-white text-[#6c0064] rounded-full p-[5px] ml-1">
                      {" "}
                      2+{" "}
                    </sup>
                  </Link>
                </div>
                {/*WELCOME SECTION*/}
                <div className="md:flex items-center justify-between w-full">
                  <div>
                    <h1
                      className="text-darkBlue"
                      style={{ fontSize: "25px", marginTop: "10px" }}
                    >
                      Welcome {user?.user?.name || user?.name}!
                    </h1>
                  </div>
                  <div>
                    <Link
                      to="/candidate/notifications"
                      className="m-hidden bg-[#6c0064] bi bi-bell text-white py-4 px-12 rounded-lg text-lg"
                    >
                      &nbsp; Notifications{" "}
                      <sup className="-mt-3 bg-white font-semibold text-[#6c0064] rounded-full p-[5px] ml-1">
                        {" "}
                        2+{" "}
                      </sup>
                    </Link>
                  </div>
                </div>
                <h5 className="pl-2 mt-8 text-xl"> Application Overview </h5>
                <section>
                <div className="mt-5 ml-3">
                  <div className="pb-0 md:flex flex-wrap items-center">
                  <span className="text-xl">Stages</span>
                  <div className="flex items-center md:ml-3 mt-3 md:mt-0">
                    <button className="w-[20px] rounded-lg h-[20px] bg-[#F35B24]"></button>
                    <span className="bi bi-arrow-right text-xl mx-3"></span>
                    <span className="text-md text-[#F35B24]">
                      {" "}
                      Application Stage{" "}
                    </span>
                  </div>
                  <div className="flex items-center md:ml-5 mt-3 md:mt-0">
                    <button className="w-[20px] rounded-lg h-[20px] bg-[#FDB919]"></button>
                    <span className="bi bi-arrow-right text-xl mx-3"></span>
                    <span className="text-md text-[#FDB919]">
                      {" "}
                      Online Test Stage{" "}
                    </span>
                  </div>
                  <div className="flex items-center md:ml-5 mt-3 md:mt-0">
                    <button className="w-[20px] rounded-lg h-[20px] bg-[#87AA4B]"></button>
                    <span className="bi bi-arrow-right text-xl mx-3"></span>
                    <span className="text-md text-[#87AA4B]">
                      {" "}
                      Assessment Day Stage{" "}
                    </span>
                  </div>
                </div>
                  </div>
                </section>
                <div className="bg-gray-100 p-8 mx-3 mb-12 mt-8 grid grid-cols-1 md:grid-cols-4">
                  <Card
                    title={"Total Applications"}
                    text={data?.data?.summary?.total}
                    withStarts={false}
                  />
                  <Card
                    title={"Online Test Stage"}
                    text={dashboardSummary?.online_test_total}
                    color="text-[#F35B24]" isLoading={dashboardSummaryLoading}
                    starts={[
                      { 
                        title: 'Completed', color: 'text-customGreen',
                        value: dashboardSummary?.online_test_completed_total
                     },
                      { 
                         title: 'Pending', color: 'text-customOrange',
                         value: dashboardSummary?.online_test_pending_total
                      },
                    ]}
                  />
                  <Card
                    title={"Assessment Day Stage"}
                    text={dashboardSummary?.physical_test_total}
                    color="text-[#FDB919]" 
                    starts={[
                      { 
                        title: 'Completed', color: 'text-customGreen',
                        value: dashboardSummary?.physical_test_completed_total
                     },
                      { 
                         title: 'Pending', color: 'text-customOrange',
                         value: dashboardSummary?.physical_test_pending_total
                      },
                    ]}
                  />
                  <Card
                    title={"Result"}
                    text={examResult?.summary?.total}
                    isLoading={examResultLoading}
                    color="text-[#87AA4B]"
                    starts={[
                      { 
                        title: 'Completed', color: 'text-customGreen',
                        value: examResult?.summary?.completed
                     },
                      { 
                         title: 'Pending', color: 'text-customOrange',
                         value: examResult?.summary?.notStarted
                      },
                    ]}
                  />
                </div>
                <section className="md:w-[80%] xl:w-[75%] md:mx-auto mb-10">
                <div className="md:flex justify-between">
                  <div className='w-full mb-5 text-center shadow md:mb-0 md:w-[48%] py-5 border border-gray-200 rounded-lg bg-white'>
                      <p className='text-lg text-center mb-3'>
                          My Practice Exams
                      </p>
                      <h1 className={`text-5xl text-center font-bold mb-3 mt-2`}>
                        { !mockExamLoading ? mockAssessment : '...' }
                      </h1>
                      <Link to={'/candidate/assessments/view-practice-exams'} className="py-3 bg-[#F35B24] text-white w-[80%] mx-auto text-center rounded-lg text-md px-12">
                         Click to view
                      </Link>
                  </div>
                  <div className='w-full mb-5 shadow md:mb-0 md:w-[48%] py-5 border border-gray-200 rounded-lg bg-white'>
                      <p className='text-lg text-center mb-3'>
                        Upcoming Exams
                      </p>
                      <h1 className={`text-5xl text-center font-bold mb-3 mt-2`}>
                         { !examsLoading ? numberOfExams : '...' }
                      </h1>
                      <Link to={'/candidate/assessments/view-assessments'} className="py-3 bg-customGreen text-white w-[80%] mx-auto text-center rounded-lg text-md px-12">
                         Click to view  
                      </Link>
                  </div>
                </div>
                </section>
                {/*APPLICATIONS*/}
                <div className="w-full mt-3">
                  <header className="bg-darkBlue py-5 px-4 rounded-md mb-4">
                    <h1 className="text-white text-lg">
                      Applications &nbsp;
                      {data?.data?.applications?.data?.length
                        ? `(${data?.data?.applications?.data?.length})`
                        : null}
                    </h1>
                  </header>
                  <Applications width={width} data={data?.data?.applications} />
                </div>
              </div>
            </div>
          </>
        )}
      </AssessementContext.Provider>
    </React.Fragment>
  );
};

export default Dashboard;
